import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
    gsap.utils.toArray('.card-project__background').forEach(container => {
        const img = container.querySelector('img');

        ScrollTrigger.create({
            trigger: container,
            start: "top bottom",
            end: "bottom top",
            scrub: true, // Utilisez scrub pour une transition fluide
            onUpdate: (self) => {
                // Calcul de la position de l'objet basée sur la progression du défilement
                const progress = self.progress; // 0.0 au début, 1.0 à la fin
                const positionY = progress * 100; // Convertit la progression en pourcentage pour object-position

                // Mise à jour de la position de l'objet pour créer l'effet de parallaxe
                img.style.objectPosition = `center ${positionY}%`;
            }
        });
    });
});

