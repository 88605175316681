import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

const scene = new THREE.Scene();
const animationContainer = document.getElementById("animation");
const width = animationContainer.clientWidth;
const height = animationContainer.clientHeight;
const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
const renderer = new THREE.WebGLRenderer({ alpha: true }); // Set alpha for transparent background
const ambientLight = new THREE.AmbientLight(0x222222, 200); // white light with intensity 200
renderer.setSize(width, height);
renderer.setClearColor(0x000000, 0); // Set clear color with 0 opacity for transparency
animationContainer.appendChild(renderer.domElement);

let gltfModel; // Variable to hold your model
let crazyStartTime = 0; // Time when crazy rotation starts

// GLTF Loader
const loader = new GLTFLoader();
loader.load('/wp-content/themes/najumi/assets/models/scene.gltf', function (gltf) {
    gltfModel = gltf.scene; // Assign the model to gltfModel
    gltfModel.scale.set(0.2, 0.2, 0.2); // Adjust scale here, for example, 0.5, 0.5, 0.5
    scene.add(ambientLight);
    scene.add(gltfModel);
}, undefined, function (error) {
    console.error(error);
});

camera.position.z = 5;

let clickCount = 0; // Track the number of clicks
let lastClickTime = Date.now();
const clickDelay = 400; // 400 ms allowed between clicks
const crazyTime = 4000; // 4 seconds of crazy behavior

let isCrazy = false; // Assuming isCrazy is a boolean
let revertTimeout; // Will store the timeout ID

animationContainer.addEventListener('click', (event) => {
    const now = Date.now();

    if (now - lastClickTime < clickDelay) {
        clickCount++;
        if (clickCount === 4) {
            isCrazy = true;
            crazyStartTime = Date.now(); // Record the time when crazy starts
            clickCount = 0; // Reset click count after triggering the Easter egg

            // Clear previous timeout if it exists
            if (revertTimeout) {
                clearTimeout(revertTimeout);
            }

            // Set a timeout to revert the isCrazy state after a certain time
            revertTimeout = setTimeout(() => {
                isCrazy = false;
                // Here, implement any additional logic to smoothly transition from crazy to normal state
            }, 5000); // 5000 ms = 5 seconds
        }
    } else {
        clickCount = 1; // Reset to 1 instead of 0 to count the current click
    }
    lastClickTime = now;
});

function animate() {
    requestAnimationFrame(animate);
    
    if (gltfModel) {
        if (isCrazy) {
            // Calculate elapsed time since the start of the crazy animation
            const elapsedTime = Date.now() - crazyStartTime;
            // Calculate the rotation speed based on elapsed time (slows down over time)
            const rotationSpeed = Math.max(0.1, 0.5 * (1 - elapsedTime / crazyTime));
            gltfModel.rotation.y += rotationSpeed;

            // Stop the crazy animation after the crazyTime has passed
            if (elapsedTime > crazyTime) {
                isCrazy = false;
            }
        } else {
            // Normal rotation behavior
            gltfModel.rotation.y += 0.01;
        }
    }

    renderer.render(scene, camera);
}

animate();

// Handle window resize
window.addEventListener('resize', onWindowResize, false);

function onWindowResize() {
    const newWidth = animationContainer.clientWidth;
    const newHeight = animationContainer.clientHeight;

    camera.aspect = newWidth / newHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(newWidth, newHeight);
}

