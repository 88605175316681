document.addEventListener('DOMContentLoaded', function () {
    let currentPrice = 0.00; // Initialize the current price

    // Update the price display with animation
    function animatePriceUpdate(newPrice) {
        const priceDisplay = document.getElementById('currentPrice');
        const duration = 500; // duration of the animation in milliseconds
        const startTime = performance.now();
        // Retrieve the starting price
        const startPrice = currentPrice;
        // Animation function
        function update() {
            const currentTime = performance.now();
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            // Update the current price based on progress
            currentPrice = startPrice + (newPrice - startPrice) * progress;
            priceDisplay.textContent = currentPrice.toFixed(2) + '€';
            // Continue the animation until progress is 1
            if (progress < 1) {
                requestAnimationFrame(update);
            }
        }
        requestAnimationFrame(update);
    }

    // Initialize each line-calculate component
    document.querySelectorAll('.line-calculate').forEach(function (component, index) {
        const checkbox = component.querySelector('.checkbox-toggle');
        const options = component.querySelectorAll('.option-card');

        // Toggle second set visibility based on checkbox
        checkbox.addEventListener('change', function () {
            toggleSecondSet(this, this.dataset.index);
            calculateTotalPrice();
        });

        // Initialize the state of the second set
        handleInitialState(checkbox, checkbox.dataset.index);

        // Handle clicks on option cards
        options.forEach(function (card) {
            card.addEventListener('click', function () {
                handleOptionClick(this);
                calculateTotalPrice();
            });
        });
    });

    // Calculate the total price of selected options
    function calculateTotalPrice() {
        let totalPrice = 0.00;
        document.querySelectorAll('.line-calculate').forEach(function (component) {
            const checkbox = component.querySelector('.checkbox-toggle');
            if (checkbox.checked) { // Only count if checkbox is checked
                const selectedOptions = component.querySelectorAll('.line-calculate__options--card.bg-grey-saturate-500');

                selectedOptions.forEach(option => {
                    const price = parseFloat(option.getAttribute('data-price'));
                    totalPrice += price;
                });
            }
        });
        // Update the total price display with animation
        animatePriceUpdate(totalPrice);
    }

    // Toggles classes to indicate selection and toggles the badge's visibility
    function toggleClass(element) {
        // Toggle the background color to indicate selection
        element.classList.toggle('bg-grey-saturate-500');
        element.classList.toggle('bg-grey-400');
        // Toggle the badge's visibility
        element.querySelector('.badgeContainer').classList.toggle('opacity-100');
    }

    // Toggles the visibility of the second set of options based on the checkbox state
    function toggleSecondSet(checkbox, index) {
        // Find the second set of options by ID
        const secondSet = document.getElementById('secondSet_' + index);
        // Toggle the 'hidden' class based on the checkbox state
        secondSet.classList.toggle('hidden', !checkbox.checked);
    }

    // Handles the initial state of the second set based on the checkbox
    function handleInitialState(checkbox, index) {
        // Initialize the second set visibility
        toggleSecondSet(checkbox, index);
    }

    // Handles clicks on option cards
    function handleOptionClick(element) {
        // Toggle classes and badge visibility on the clicked card
        toggleClass(element);
    }
});